import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Button,
  useTheme,
  Link,
} from '@mui/material'
import DirectoryContactDialog from 'src/components/Dialog/DirectoryContactDialog'

const DirectoryTable = ({
  healthcareSystems,
  sortConfig = { key: 'name', direction: 'asc' },
  sortData,
}) => {
  const theme = useTheme()
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const { signedIn } = useSelector(state => state.identity)

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      justifyContent='center'
      sx={{
        marginBottom: 3,
        padding: '20px',
        maxWidth: '70em',
      }}
    >
      {/* Global Feedback Button */}
      {signedIn && (
        <Grid container justifyContent='space-between' sx={{ marginTop: 1, marginBottom: 1 }}>
          <Grid item>
            <Button variant='outlined' color='primary' onClick={() => setFeedbackOpen(true)}>
              Report Incorrect Information
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Table Container */}
      <TableContainer
        sx={{
          minWidth: '1000px',
          width: '100%',
          overflowX: 'auto',
        }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ backgroundColor: theme.palette.secondary.dark }}>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={sortConfig.direction || 'asc'}
                  onClick={() => sortData('name')}
                  sx={{
                    color: 'white',
                    '&:hover': { color: 'white' },
                    '&.Mui-active': { color: 'white' },
                    '& .MuiTableSortLabel-icon': { color: 'white' },
                  }}
                >
                  Healthcare System
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white' }}>Address</TableCell>
              <TableCell sx={{ color: 'white' }}>Phone Number</TableCell>
              <TableCell sx={{ color: 'white' }}>Recruiter Emails</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!signedIn && (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '20px' }}>
                  <Typography variant='body1' color='textSecondary'>
                    Become a member to see more contact information!
                  </Typography>
                  <Link
                    href='/login'
                    underline='hover'
                    color='primary'
                    sx={{ marginRight: '20px' }}
                  >
                    Sign in
                  </Link>{' '}
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{ mt: 1 }}
                    component={Link}
                    href='/register/physicians'
                  >
                    Sign Up
                  </Button>
                </TableCell>
              </TableRow>
            )}

            {healthcareSystems.map(system => (
              <React.Fragment key={system.id}>
                {/* Main Healthcare System Row */}
                <TableRow>
                  <TableCell>
                    <Typography variant='body1' fontWeight='bold'>
                      {system.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{system.address}</TableCell>
                  <TableCell>{system.phone}</TableCell>
                  <TableCell>
                    {signedIn &&
                      system.employerUserEmails &&
                      system.employerUserEmails
                        .filter(
                          email =>
                            !system.campuses?.some(campus =>
                              (campus.employerUserEmails || []).includes(email),
                            ),
                        )
                        .map((email, index) => (
                          <Typography
                            key={index}
                            variant='body2'
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <a href={`mailto:${email}`}>{email}</a>
                          </Typography>
                        ))}
                  </TableCell>
                </TableRow>

                {/* Campus Rows */}
                {system.campuses &&
                  system.campuses.map((campus, index) => (
                    <TableRow
                      key={`${system.id}-campus-${index}`}
                      sx={{ backgroundColor: '#f9f9f9' }}
                    >
                      <TableCell>
                        <Typography variant='body2' sx={{ paddingLeft: 2 }}>
                          {campus.name}
                        </Typography>
                      </TableCell>
                      <TableCell>{campus.address}</TableCell>
                      <TableCell>{campus.phone}</TableCell>
                      <TableCell>
                        {signedIn &&
                          (campus.employerUserEmails || []).map((email, index) => (
                            <Typography
                              key={index}
                              variant='body2'
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <a href={`mailto:${email}`}>{email}</a>
                            </Typography>
                          ))}
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DirectoryContactDialog feedbackOpen={feedbackOpen} setFeedbackOpen={setFeedbackOpen} />
    </Grid>
  )
}

export default DirectoryTable
