import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  CircularProgress,
  Button,
  TextField,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
  useMediaQuery,
} from '@mui/material'
import { fetchCreateContactEmail } from 'src/redux/contact'
import { validateEmail } from 'data/helpers' // Reuse your email validation helper

export default function ContactUs() {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm')) // Check for small screens

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleChange = useCallback(
    name => e => {
      const value = e.target.value
      setValues(prev => ({ ...prev, [name]: value }))

      // Validate input
      const newErrors = { ...errors }

      if (name === 'email') {
        if (!validateEmail(value)) {
          newErrors.email = 'Invalid email format.'
        } else {
          delete newErrors.email
        }
      }

      if (!value.trim()) {
        newErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`
      } else if (name !== 'email') {
        delete newErrors[name]
      }

      setErrors(newErrors)
    },
    [errors],
  )

  const handleSubmit = async () => {
    const hasErrors = Object.keys(errors).length > 0
    if (hasErrors || loading) return

    setLoading(true)
    try {
      await dispatch(fetchCreateContactEmail({ values })) // Submit data
      setSubmitted(true) // Show Thank You message
    } catch (error) {
      console.error('Failed to send contact email:', error)
    } finally {
      setLoading(false)
    }
  }

  if (submitted) {
    return (
      <Paper
        elevation={4}
        sx={{
          padding: 3,
          maxWidth: isMobile ? '90%' : 600,
          margin: 'auto',
          marginTop: 4,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Typography align='center' variant='h4' gutterBottom>
          Thank You!
        </Typography>
        <Typography align='center' variant='body2' gutterBottom>
          Your message has been successfully submitted. We’ll get back to you within 48 hours.
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Typography align='center' variant='body1'>
          <Link href='/' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
            Go to the home page
          </Link>
        </Typography>
      </Paper>
    )
  }

  return (
    <Grid
      container
      justifyContent='center'
      sx={{
        height: '100%',
        marginTop: 2,
        paddingX: isMobile ? 2 : 4,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: isMobile ? 2 : 4,
          maxWidth: isMobile ? '90%' : 600,
          margin: 'auto',
          marginTop: 4,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Typography align='center' variant='h5' gutterBottom>
          Send MyStethi a message!
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Grid container direction='column' spacing={2}>
          {loading && <CircularProgress size={24} sx={{ margin: 'auto' }} />}
          <Grid item>
            <Typography align='center' variant='body1'>
              Interested in learning more? Send us a message, and we'll get back to you within 48
              hours.
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label='Email'
              variant='outlined'
              value={values.email}
              onChange={handleChange('email')}
              error={!!errors.email}
              helperText={errors.email || ' '}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label='First Name'
              variant='outlined'
              value={values.firstName}
              onChange={handleChange('firstName')}
              error={!!errors.firstName}
              helperText={errors.firstName || ' '}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label='Last Name'
              variant='outlined'
              value={values.lastName}
              onChange={handleChange('lastName')}
              error={!!errors.lastName}
              helperText={errors.lastName || ' '}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label='Message'
              variant='outlined'
              multiline
              rows={4}
              value={values.message}
              onChange={handleChange('message')}
              error={!!errors.message}
              helperText={errors.message || ' '}
            />
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              disabled={loading || Object.keys(errors).length > 0}
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Typography align='center' variant='body2'>
              Prefer email? Reach us at{' '}
              <Link
                href='mailto:contact@mystethi.com?subject=MyStethi Support'
                sx={{ textDecoration: 'underline' }}
              >
                contact@mystethi.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
