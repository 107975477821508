import React from 'react'
import {
  SEND_SUPPORT_EMAIL,
  SEND_SUPPORT_EMAIL_SUCCESS,
  SEND_SUPPORT_EMAIL_FAILURE,
} from '../constants'
import { fetchDispatch } from 'data/helpers'
import { useDispatch } from 'react-redux'

export function sendSupportEmail(values, dispatch, onError = undefined, setState) {
  dispatch({ type: SEND_SUPPORT_EMAIL })
  setState && setState(prev => ({ ...prev, loading: true, formSubmitted: false }))
  fetchDispatch({
    path: '/api/emails/support',
    values,
    responseOnError: false,
    onResponse: () => {
      dispatch(sendSupportEmailSuccess())
      setState && setState(prev => ({ ...prev, loading: false, formSubmitted: true }))
    },
    onError: error => {
      onError && onError(error)
      dispatch(sendSupportEmailFailure())
      setState && setState(prev => ({ ...prev, loading: false, formSubmitted: false }))
    },
  })
}

function sendSupportEmailSuccess() {
  return {
    type: SEND_SUPPORT_EMAIL_SUCCESS,
  }
}

function sendSupportEmailFailure() {
  return {
    type: SEND_SUPPORT_EMAIL_FAILURE,
  }
}

export function useSendSupportEmail() {
  const dispatch = useDispatch()
  return React.useCallback(
    ({ values, onError, setState }) => {
      sendSupportEmail(values, dispatch, onError, setState)
    },
    [dispatch],
  )
}

export function useHandleSendSupportEmail() {
  const sendEmail = useSendSupportEmail()
  return React.useCallback(
    ({ values, onError, setState }) => () => sendEmail({ values, onError, setState }),
    [sendEmail],
  )
}
