import { fetchDispatch } from 'data/helpers'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'

export const GET_EMPLOYER_DIRECTORY_LOADING = 'directory/GET_EMPLOYER_DIRECTORY_LOADING'
export const GET_EMPLOYER_DIRECTORY_SUCCESS = 'directory/GET_EMPLOYER_DIRECTORY_SUCCESS'
export const GET_EMPLOYER_DIRECTORY_FAILURE = 'directory/GET_EMPLOYER_DIRECTORY_FAILURE'

export const getEmployerDirectoryLoading = () => ({ type: GET_EMPLOYER_DIRECTORY_LOADING })
export const getEmployerDirectorySuccess = payload => ({
  type: GET_EMPLOYER_DIRECTORY_SUCCESS,
  payload,
})
export const getEmployerDirectoryFailure = payload => ({
  type: GET_EMPLOYER_DIRECTORY_FAILURE,
  payload,
})

export const fetchAllEmployerDirectory = () => {
  return async dispatch => {
    dispatch(getEmployerDirectoryLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/employer_directories',
        method: 'GET',
      })
      console.log('in success')
      dispatch(getEmployerDirectorySuccess(response))
    } catch (error) {
      console.log('in error')
      dispatch(
        showSnackbar(
          'Sorry, we encountered an error accessing the directory. Check back again shortly!',
          snackbarTypes.ERROR,
        ),
      )
      dispatch(getEmployerDirectoryFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  directoryEntries: [],
}

export const employerDirectoryReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_EMPLOYER_DIRECTORY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_EMPLOYER_DIRECTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        directoryEntries: payload.employerDirectories,
      }
    case GET_EMPLOYER_DIRECTORY_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
