import { fetchDispatch } from 'data/helpers'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import * as config from 'config'

export const CREATE_HOUSING_INTEREST_LOADING = 'housingInterest/CREATE_HOUSING_INTEREST_LOADING'
export const CREATE_HOUSING_INTEREST_SUCCESS = 'housingInterest/CREATE_HOUSING_INTEREST_SUCCESS'
export const CREATE_HOUSING_INTEREST_FAILURE = 'housingInterest/CREATE_HOUSING_INTEREST_FAILURE'

export const createHousingInterestLoading = () => ({ type: CREATE_HOUSING_INTEREST_LOADING })
export const createHousingInterestSuccess = () => ({ type: CREATE_HOUSING_INTEREST_SUCCESS })
export const createHousingInterestFailure = payload => ({
  type: CREATE_HOUSING_INTEREST_FAILURE,
  payload,
})

export const fetchCreateHousingInterest = query => {
  return async dispatch => {
    dispatch(createHousingInterestLoading())
    try {
      await fetchDispatch({
        path: '/api/housing_interest',
        values: query,
        method: 'POST',
      })
      dispatch(createHousingInterestSuccess())
      dispatch(showSnackbar('Submitted contact form!', snackbarTypes.SUCCESS))

      // Now, make the FollowUpBoss API call
      const apiKey = config.get('fubAPIKey')
      if (apiKey) {
        const base64Auth = btoa(`${apiKey}:`) // Encode API key with an empty password
        const followUpBossUrl = 'https://api.followupboss.com/v1/people'

        const followUpBossData = {
          firstName: query.firstName,
          lastName: query.lastName,
          emails: [{ value: query.email, type: 'work' }],
          phones: [{ value: query.phone, type: 'mobile' }],
          sourceUrl: 'www.mystethi.com',
          source: 'MyStethi',
          type: 'General Inquiry',
        }

        const followUpResponse = await fetch(followUpBossUrl, {
          method: 'POST',
          headers: {
            Authorization: `Basic ${base64Auth}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(followUpBossData),
        })

        if (!followUpResponse.ok) {
          dispatch(
            showSnackbar(
              'There was an error submitting your form, please try again later',
              snackbarTypes.ERROR,
            ),
          )
          dispatch(createHousingInterestFailure())
        }
      }

      dispatch(
        fetchTrackEvent({
          eventName: 'housing_interest_submitted',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: query.email,
        }),
      )
    } catch (error) {
      dispatch(
        showSnackbar(
          'There was an error submitting your form, please try again later',
          snackbarTypes.ERROR,
        ),
      )
      dispatch(createHousingInterestFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
}

export const housingInterestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_HOUSING_INTEREST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case CREATE_HOUSING_INTEREST_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_HOUSING_INTEREST_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
