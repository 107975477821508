import React from 'react'
import {
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Link,
  FormControl,
  InputLabel,
  useTheme,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from 'common/styles'
import Loader from 'components/Loader'
import { useFormControls } from './formActions'
import { States } from 'data/constants'
import { useHandleChangeRoute } from 'data/hooks'
import { fetchRegistrationData } from '../../../redux/supportingData'

const RegistrationForm = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const { loading: registerLoading, email } = useSelector(state => state.register)
  const { loading, specialties, subspecialties, referralSources, candidateTypes } = useSelector(
    state => state.supportingData,
  )
  const formLoading = registerLoading || loading
  const [specialtyOptions, setSpecialtyOptions] = React.useState([])
  const [subspecialtyOptions, setSubspecialtyOptions] = React.useState([])
  const {
    handleInputValue,
    errors,
    formIsValid,
    handleFormSubmit,
    values,
    setSpecialtyId,
    setSubspecialtyId,
  } = useFormControls()

  React.useEffect(() => {
    dispatch(fetchRegistrationData())
  }, [])

  React.useEffect(() => {
    const candidateType = candidateTypes.find(x => x.id === Number(values.candidateTypeId))
    const newSpecialtyOptions = specialties.filter(
      x => candidateType?.specialtyIds.includes(x.id) === true,
    )
    setSpecialtyOptions(newSpecialtyOptions)
    setSpecialtyId('')
    setSubspecialtyId('')
  }, [values.candidateTypeId])

  React.useEffect(() => {
    const specialty = specialties.find(x => x.id === Number(values.specialtyId))
    const newSubspecialtyOptions = subspecialties.filter(
      x => specialty?.subspecialtyIds.includes(x.id) === true,
    )
    setSubspecialtyOptions(newSubspecialtyOptions)
    setSubspecialtyId('')
  }, [values.specialtyId])

  const referralSourceOptions = referralSources || []
  const otherReferral = referralSources.find(r => r.name === 'Other') || { id: '', name: 'Other' }

  const handleChangeRoute = useHandleChangeRoute()
  return (
    <Grid container direction='column'>
      {formLoading ? (
        <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '100vh' }}>
          <Loader />
        </Grid>
      ) : (
        <>
          <Grid container direction='column'>
            <Grid
              container
              alignItems='center'
              direction='row'
              style={{ paddingTop: '30px', paddingLeft: '50px' }}
            >
              <Grid container style={{ marginLeft: '20px' }} flexDirection='column'>
                <Typography variant='h4'>Welcome To MyStethi</Typography>
                <Typography variant='caption' style={{ fontStyle: 'normal' }}>
                  Already have an account?{' '}
                  <Link
                    onClick={handleChangeRoute('/login')}
                    style={{
                      cursor: 'pointer',
                      fontStyle: 'italic',
                      textDecoration: 'underline',
                    }}
                    underline='hover'
                  >
                    Login
                  </Link>
                </Typography>
                <Typography variant='caption' style={{ fontStyle: 'normal' }}>
                  Are you a healthcare employer looking to register with us?
                  <Link
                    onClick={handleChangeRoute('/register/hospitals')}
                    style={{
                      cursor: 'pointer',
                      fontStyle: 'italic',
                      textDecoration: 'underline',
                    }}
                    underline='hover'
                  >
                    Contact us here
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid item className={classes.registrationFieldsContainer}>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      key='first'
                      onBlur={handleInputValue}
                      onChange={handleInputValue}
                      name='firstName'
                      label='First Name'
                      multiline={false}
                      rows={1}
                      required={true}
                      variant='outlined'
                      autoComplete='none'
                      style={{ width: '100%', color: values.firstName ? 'black' : 'inherit' }}
                      {...(errors['firstName'] && {
                        error: true,
                        helperText: errors['firstName'],
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      key='last'
                      onBlur={handleInputValue}
                      onChange={handleInputValue}
                      name='lastName'
                      label='Last Name'
                      required={true}
                      multiline={false}
                      rows={1}
                      autoComplete='none'
                      variant='outlined'
                      style={{ width: '100%', color: values.lastName ? 'black' : 'inherit' }}
                      {...(errors['lastName'] && {
                        error: true,
                        helperText: errors['lastName'],
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      key='email'
                      onBlur={handleInputValue}
                      onChange={handleInputValue}
                      defaultValue={email}
                      name='email'
                      label='Email'
                      required={true}
                      multiline={false}
                      rows={1}
                      style={{ width: '100%', color: values.email ? 'black' : 'inherit' }}
                      autoComplete='none'
                      variant='outlined'
                      {...(errors['email'] && {
                        error: true,
                        helperText: errors['email'],
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel id='profession-label' sx={{ background: 'white', px: 0.5 }}>
                        Profession *
                      </InputLabel>
                      <Select
                        labelId='profession-label'
                        name='candidateTypeId'
                        id='candidateTypeId'
                        required={true}
                        onChange={handleInputValue}
                        value={values.candidateTypeId ? values.candidateTypeId : ''}
                        sx={{
                          color: values.candidateTypeId ? 'black' : theme.palette.primary,
                        }}
                        displayEmpty
                      >
                        <MenuItem value='' disabled>
                          <em>Profession</em>
                        </MenuItem>
                        {candidateTypes.map(s => (
                          <MenuItem key={s.id} value={s.id.toString()}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors['candidateId'] && (
                      <FormHelperText style={{ color: 'red' }}>
                        {errors['candidateId']}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: specialtyOptions.length > 0 ? 'block' : 'none' }}
                  >
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel id='specialty-label' sx={{ background: 'white', px: 0.5 }}>
                        Specialty *
                      </InputLabel>
                      <Select
                        labelId='specialty-label'
                        disabled={values.candidateTypeId === null}
                        name='specialtyId'
                        id='specialtyId'
                        required={specialtyOptions.length > 0 ? true : false}
                        onChange={handleInputValue}
                        value={values.specialtyId ? values.specialtyId : ''}
                        sx={{
                          color: values.specialtyId ? 'black' : theme.palette.primary,
                        }}
                      >
                        <MenuItem value='' disabled>
                          <em>Specialty</em>
                        </MenuItem>
                        {specialtyOptions.map(s => (
                          <MenuItem key={s.id} value={s.id.toString()}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors['specialtyId'] && (
                        <FormHelperText style={{ color: 'red' }}>
                          {errors['specialtyId']}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: subspecialtyOptions.length > 0 ? 'block' : 'none' }}
                  >
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel id='specialty-label' sx={{ background: 'white', px: 0.5 }}>
                        Sub-Specialty
                      </InputLabel>
                      <Select
                        labelId='sub-specialty-label'
                        name='subspecialtyId'
                        id='subspecialtyId'
                        value={values.subspecialtyId ? values.subspecialtyId : ''}
                        onChange={handleInputValue}
                        sx={{
                          color: values.subspecialtyId ? 'black' : theme.palette.primary,
                        }}
                      >
                        <MenuItem value='' disabled>
                          <em>Sub-Specialty</em>
                        </MenuItem>
                        {subspecialtyOptions.map(s => (
                          <MenuItem key={s.id} value={s.id.toString()}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} md={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        key='city'
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        name='city'
                        label='City'
                        multiline={false}
                        rows={1}
                        variant='outlined'
                        autoComplete='none'
                        style={{ width: '100%', color: values.city ? 'black' : 'inherit' }}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant='outlined' fullWidth>
                        <InputLabel id='state-label' sx={{ background: 'white', px: 0.5 }}>
                          State *
                        </InputLabel>
                        <Select
                          labelId='state-label'
                          name='state'
                          id='state'
                          value={values.state || ''}
                          onChange={handleInputValue}
                          displayEmpty
                          sx={{
                            color: values.state ? 'black' : theme.palette.primary,
                          }}
                        >
                          <MenuItem value='' disabled>
                            <em>State</em>
                          </MenuItem>
                          {Object.keys(States).map(stateKey => (
                            <MenuItem key={stateKey} value={stateKey}>
                              {States[stateKey]}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.state && <FormHelperText error>{errors.state}</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      key='password'
                      onBlur={handleInputValue}
                      onChange={handleInputValue}
                      type='password'
                      name='password'
                      label='Password'
                      variant='outlined'
                      required={true}
                      multiline={false}
                      style={{ width: '100%', color: values.password ? 'black' : 'inherit' }}
                      rows={1}
                      autoComplete='none'
                      {...(errors['password'] && {
                        error: true,
                        helperText: errors['password'],
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      key='passwordConfirm'
                      onBlur={handleInputValue}
                      onChange={handleInputValue}
                      name='confirmPassword'
                      type='password'
                      variant='outlined'
                      label='Confirm Password'
                      required={true}
                      multiline={false}
                      style={{ width: '100%', color: values.confirmPassword ? 'black' : 'inherit' }}
                      rows={1}
                      autoComplete='none'
                      {...(errors['confirmPassword'] && {
                        error: true,
                        helperText: errors['confirmPassword'],
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel id='referral-source-label' sx={{ background: 'white', px: 0.5 }}>
                        How did you hear about us? *
                      </InputLabel>
                      <Select
                        labelId='referral-source-label'
                        name='referralSourceId'
                        id='referralSourceId'
                        required={true}
                        onChange={handleInputValue}
                        value={values.referralSourceId !== null ? values.referralSourceId : ''}
                        sx={{
                          color: values.referralSourceId ? 'black' : theme.palette.primary,
                        }}
                      >
                        {referralSourceOptions.map(s => (
                          <MenuItem key={s.id} value={s.id.toString()}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors['referralSourceId'] && (
                        <FormHelperText style={{ color: 'red' }}>
                          {errors['referralSourceId']}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      key='referralSourceOther'
                      onBlur={handleInputValue}
                      onChange={handleInputValue}
                      name='referralSourceOther'
                      placeholder='Interesting! Tell us more'
                      multiline={false}
                      rows={1}
                      style={
                        values.referralSourceId === otherReferral.id.toString()
                          ? { width: '100%', fontSize: '.8rem', color: 'black' }
                          : { display: 'none' }
                      }
                      autoComplete='none'
                      variant='outlined'
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.registrationBtnContainer}>
                    <Typography
                      variant='caption'
                      style={{ alignSelf: 'center', fontStyle: 'normal' }}
                    >
                      By clicking "Create Account" you are creating a "MyStethi" account and are
                      agreeing to our{' '}
                      <Link
                        href='/tac'
                        underline='always'
                        color='#1B1B1B'
                        target='_blank'
                        and
                        rel='noopener noreferrer'
                      >
                        Terms of use
                      </Link>{' '}
                      and{' '}
                      <Link
                        href='/privacy'
                        underline='always'
                        target='_blank'
                        and
                        rel='noopener noreferrer'
                        color='#1B1B1B'
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                    <Grid container justifyContent='center'>
                      <Button
                        type='submit'
                        disabled={!formIsValid() || formLoading}
                        autoFocus
                        variant='contained'
                        style={{ marginTop: '20px' }}
                      >
                        Create Account
                      </Button>
                      {formLoading && <Loader className={classes.registrationLoader} />}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <div className={classes.mobileSubmitButtonHeight}></div>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default RegistrationForm
