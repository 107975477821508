// Layout
export const SET_SPECIALTIES = 'SET_SPECIALTIES'
export const GET_SPECIALTIES = 'GET_SPECIALTIES'
export const UPDATE_JOB_FILTERS_HEIGHT = 'UPDATE_JOB_FILTERS_HEIGHT'

// Accounts
export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'

// Users
export const GET_USERS = 'GET_USERS'
export const SET_USERS = 'SET_USERS'
export const SET_USER = 'SET_USER'

// Candidate
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE'

// Employer User
export const UPDATE_EMPLOYER_USER = 'UPDATE_EMPLOYER_USER'

// Employer
export const UPDATE_EMPLOYER = 'UPDATE_EMPLOYER'

// Table
export const UPDATE_TABLE = 'UPDATE_TABLE'

// Dialog
export const UPDATE_DIALOG = 'UPDATE_DIALOG'

// Popover
export const OPEN_POPOVER = 'OPEN_POPOVER'
export const CLOSE_POPOVER = 'CLOSE_POPOVER'

// Contact
export const SEND_SUPPORT_EMAIL = 'SEND_SUPPORT_EMAIL'
export const SEND_SUPPORT_EMAIL_SUCCESS = 'SEND_SUPPORT_EMAIL_SUCCESS'
export const SEND_SUPPORT_EMAIL_FAILURE = 'SEND_SUPPORT_EMAIL_FAILURE'

export const States = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  DC: 'Washington, D.C.',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const Cities = {
  Austin: 'Austin',
  Philadelphia: 'Philadelphia',
}

// snackbar types
export const snackbarTypes = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
})

export const getAlertCadenceOptions = () => {
  return [
    { id: 2, name: 'Daily' },
    { id: 2, name: 'Weekly' },
    { id: 3, name: 'Monthly' },
  ]
}

