import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Chip,
  useTheme,
  Divider,
  Autocomplete,
  Tooltip,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { fetchCreateUserFilter } from 'src/redux/filters'
import { useDialogState } from 'data/dialog/actions'
import { filterDefinitions } from 'components/Filters/filterDefinitions'

const alertCadenceOptions = [
  { value: 0, label: 'None' },
  { value: 1, label: 'Daily' },
  { value: 2, label: 'Weekly' },
  { value: 3, label: 'Monthly' },
]

export default function Content({
  dialogId,
  selectedFilters,
  filterTypes,
  listType,
  handleDialogClose,
}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [, setState] = useDialogState(dialogId)
  const { userId, email, employerUserId } = useSelector(state => state.identity)
  const [userFilterName, setUserFilterName] = React.useState('')
  const [alertCadence, setAlertCadence] = React.useState('None')
  const [searchParams] = useSearchParams()
  const filterTypeId = filterTypes.find(
    filterType => filterType.name.toLowerCase() === listType.toLowerCase(),
  ).id
  const hideAlertOption = listType === 'job' && employerUserId

  const tooltipTitle =
    listType === 'job'
      ? '(Optional): Set an email alert when new jobs fit this criteria'
      : '(Optional): Set an email alert when new candidates fit this criteria'

  const onChange = event => {
    const name = event.target.value
    setUserFilterName(name)
  }

  const onAlertChange = (event, option) => {
    const cadence = option ? option.value : ''
    setAlertCadence(cadence)
  }

  const handleSubmit = () => {
    const saveableFilters = new URLSearchParams()
    // Loop through each search parameter
    for (const [key, value] of searchParams.entries()) {
      // Check if the key is in filterDefinitions and is saveable
      const filterDefinition = filterDefinitions.find(def => def.queryStringKey === key)
      if (!filterDefinition) {
        continue
      }
      if (filterDefinition && filterDefinition.isSavableFilter) {
        // If it's a saveable filter, add it to the saveableFilters
        saveableFilters.set(key, value)
      }
    }
    dispatch(
      fetchCreateUserFilter({
        userId: userId,
        name: userFilterName,
        alertCadence: alertCadence,
        queryString: saveableFilters.toString(),
        filterTypeId: filterTypeId,
        email: email,
      }),
    )
    setState(prev => ({ ...prev, open: false }))
    handleDialogClose()
  }

  const params = Object.values(selectedFilters).map(filter => {
    return {
      name: filter.filterDefinition.displayName,
      values: filter.selectedValues.map(value => value.name),
    }
  })

  return (
    <Grid
      container
      item
      xs={12}
      direction='column'
      justifyContent='space-between'
      alignItems='center'
      sx={{ padding: '20px' }}
    >
      <Grid item xs={12}>
        <TextField
          required
          size='small'
          id='outlined-required'
          value={userFilterName}
          placeholder='Name your filter'
          sx={{ padding: '10px 0' }}
          onChange={onChange}
        />
      </Grid>
      {params.map((param, paramIndex) => (
        <Grid
          key={`param-${paramIndex}`}
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ padding: '5px 10px', width: '100%' }}
        >
          <Grid item xs={6}>
            <Typography variant='subtitle1'>{param.name}</Typography>
          </Grid>
          <Grid container direction='row' item xs={6} justifyContent='flex-end'>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
              {param.values.map((value, valueIndex) => (
                <Grid
                  key={`param-${paramIndex}-value-${valueIndex}`}
                  item
                  container
                  direction='row'
                  justifyContent='flex-end'
                  xs={12}
                  aligntext='right'
                >
                  <Chip
                    sx={{
                      height: '20px',
                      backgroundColor: theme.palette.secondary.light,
                      borderRadius: 0,
                      width: '220px',
                      '& .MuiChip-label': {
                        fontSize: '.8rem',
                      },
                    }}
                    key={value}
                    label={value}
                  />
                </Grid>
              ))}
            </Box>
          </Grid>
          <Divider color='secondary' sx={{ height: '1px' }} />
        </Grid>
      ))}
      {!hideAlertOption && (
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignItems='flex-start'
          sx={{ marginTop: '20px' }}
        >
          <Autocomplete
            size='small'
            id='alert-cadence'
            options={alertCadenceOptions}
            sx={{ width: '60%', '& .MuiFormLabel-root': { color: 'gray' } }}
            onChange={onAlertChange}
            value={alertCadenceOptions.find(option => option.value === alertCadence) || null}
            renderInput={params => <TextField size='small' {...params} label='Alert Cadence' />}
          />
          <span>
            <Tooltip placement='top-start' title={tooltipTitle} arrow={true}>
              <InfoIcon sx={{ fontSize: '.8rem', color: 'gray', marginLeft: '10px' }} />
            </Tooltip>
          </span>
        </Grid>
      )}
      <Grid item>
        <Button
          disabled={!userFilterName}
          variant='contained'
          size='large'
          onClick={handleSubmit}
          sx={{ marginTop: '20px' }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}
