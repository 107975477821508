import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Box, Button, Grid, TextField } from '@mui/material'
import { useSpecialties } from 'data/layout/actions'
import { useIdentity } from 'pages/Identity/Login'
import GenericSectionHeader from '../GenericComponents/GenericSectionHeader'
import GenericCollapsableSection from '../GenericComponents/GenericCollapsableSection'
import { TextEditor } from 'components/TextEditor/TextEditor'
import JobTextField from './JobTextField'
import JobMultiSelectTextField from './JobMultiSelectTextField'
import JobSingleSelectTextField from './JobSingleSelectTextField'
import JobChipField from './JobChipField'
import JobRadioGroup from './JobRadioGroup'
import JobCheckboxField from './JobCheckboxField' // Updated to support multiple checkbox selections
import {
  ACADEMIC_ICONS,
  AREA_ICONS,
  academicOptions,
  areaOptions,
  practiceTypeOptions,
  salaryTypeOptions,
  shiftTypeOptions,
  stateOptions,
  visaOptions,
  yesNoOptions,
} from './constants'
import { fetchCreateJob, fetchUpdateJob } from 'src/redux/job'
import { fetchGetEmployerUsers } from 'src/redux/users'
import { PRACTICE_TYPE_HELPER_TEXT, ACADEMIC_TYPE_HELPER_TEXT } from 'data/fieldHelperText'

const JobInformationSection = ({
  jobToEdit,
  isEdit,
  onResponse,
  onClose,
  handleSuccessfulSavedJob,
  isOpenByDefault,
  setJobAlertData,
  handleSuccessfulJobEdit,
  title,
  disabled,
}) => {
  const { specialties, subspecialties, candidateTypes } = useSpecialties()
  const dispatch = useDispatch()
  const { users } = useSelector(state => state.users)
  const employerUserOptions = users.map(user => ({ id: user.id, name: user.email }))
  const { employerUserId, isEmployerAdmin, employerId } = useIdentity()
  const [values, setValues] = useState({
    about: null,
    aboutRaw: null,
    title: null,
    candidateTypeId: null,
    city: null,
    state: null,
    practiceType: null,
    salaryType: null,
    specialtyIds: [],
    subspecialtyIds: [],
    visaIds: [], // Updated to support an array of IDs
    loanForgiveness: null,
    pslfCompatible: null,
    area: null,
    academicType: null,
    shiftType: null,
    salaryMin: null,
    salaryMax: null,
    salary: null,
    employerUserId: employerUserId,
  })

  const [formSpecialties, setFormSpecialties] = useState([])
  const [formSubspecialties, setFormSubspecialties] = useState([])
  const [showError, setShowError] = useState(false)
  const [didInitialize, setDidInitialize] = useState(false)

  const candidateTypeOptions = candidateTypes.map(({ id, name }) => ({ id, name }))

  useEffect(() => {
    if (employerId) {
      dispatch(fetchGetEmployerUsers(employerId))
    }
  }, [employerId])

  useEffect(() => {
    if (jobToEdit && !didInitialize && isEdit) {
      setValues({
        id: jobToEdit.id ?? null,
        about: jobToEdit.about ?? null,
        aboutRaw: jobToEdit.aboutRaw ?? null,
        title: jobToEdit.title ?? null,
        candidateTypeId: jobToEdit.candidateTypeId ?? null,
        city: jobToEdit.city ?? null,
        state: jobToEdit.state ?? null,
        practiceType: jobToEdit.practiceType ?? null,
        salaryType: jobToEdit.salaryType ?? null,
        specialtyIds: jobToEdit.specialties?.map(specialty => specialty.id) ?? [],
        subspecialtyIds: jobToEdit.subspecialties?.map(subspecialty => subspecialty.id) ?? [],
        visaIds: jobToEdit.visas?.map(visa => visa.id) ?? [], // Updated to handle an array
        loanForgiveness: jobToEdit.loanForgiveness ?? null,
        pslfCompatible: jobToEdit.pslfCompatible ?? null,
        area: jobToEdit.area ?? null,
        academicType: jobToEdit.academicType ?? null,
        shiftType: jobToEdit.shiftType ?? null,
        salaryMin: jobToEdit.salaryMin ?? null,
        salaryMax: jobToEdit.salaryMax ?? null,
        salary: jobToEdit.salary ?? null,
        employerUserId: jobToEdit.employerUserId ?? employerUserId,
      })
      setDidInitialize(true)
    }
  }, [jobToEdit, didInitialize, isEdit])

  const memoizedSpecialties = useMemo(() => specialties, [specialties])
  const memoizedSubspecialties = useMemo(() => subspecialties, [subspecialties])
  const memoizedCandidateTypes = useMemo(() => candidateTypes, [candidateTypes])

  useEffect(() => {
    if (values.candidateTypeId && memoizedSpecialties.length && memoizedCandidateTypes.length) {
      const relevantSpecialties = memoizedSpecialties.filter(s =>
        memoizedCandidateTypes
          .find(ct => ct.id === values.candidateTypeId)
          ?.specialtyIds.includes(s.id),
      )
      setFormSpecialties(relevantSpecialties)
    }
  }, [values.candidateTypeId, memoizedSpecialties, memoizedCandidateTypes])

  useEffect(() => {
    if (values.specialtyIds.length && memoizedSubspecialties.length && formSpecialties.length) {
      const updatedSubspecialties = memoizedSubspecialties.filter(sub =>
        values.specialtyIds.some(id =>
          formSpecialties.some(sp => sp.id === id && sp.subspecialtyIds.includes(sub.id)),
        ),
      )
      setFormSubspecialties(updatedSubspecialties)
    }
  }, [values.specialtyIds, memoizedSubspecialties, formSpecialties])

  const handleFieldChange = (name, val) => {
    setValues(prev => ({ ...prev, [name]: val }))
  }

  const handleEditorChange = ({ about, aboutRaw }) => {
    setValues(prev => ({ ...prev, about, aboutRaw }))
  }

  const areRequiredFieldsFilled = useCallback(
    () =>
      values.title &&
      values.candidateTypeId &&
      values.practiceType &&
      values.city &&
      values.state &&
      values.salaryType &&
      values.salaryMin &&
      values.salaryMax,
    [values],
  )

  const handleSuccess = () => {
    handleSuccessfulSavedJob()
  }

  const handleSubmit = useCallback(() => {
    if (!areRequiredFieldsFilled()) {
      setShowError(true)
      return
    }

    const jobData = {
      ...values,
      employerUserId: values.employerUserId,
      visaIds: values.visaIds, // Pass the array as-is
      areaType: values.area,
      shiftType: values.candidateTypeId === 2 ? values.shiftType : null,
    }

    if (isEdit) {
      dispatch(fetchUpdateJob({ values: jobData, onSuccess: handleSuccessfulJobEdit }))
    } else {
      dispatch(fetchCreateJob({ values: jobData, employerId: employerId }))
    }
    handleSuccess()
  }, [values, employerUserId, onResponse, isEmployerAdmin, areRequiredFieldsFilled])

  return (
    <GenericCollapsableSection title={title} isOpenByDefault={isOpenByDefault} disabled={disabled}>
      <Grid item xs={12} mb={1}>
        <JobSingleSelectTextField
          label='Recruiter'
          name='employerUserId'
          value={values.employerUserId}
          options={employerUserOptions}
          setState={handleFieldChange}
        />
      </Grid>
      {/* Basics Section */}
      <GenericSectionHeader title='Basics'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <JobTextField
              required
              label='Job Title'
              name='title'
              showError={showError}
              value={values.title}
              setState={handleFieldChange}
              setJobAlertData={setJobAlertData}
            />
          </Grid>
          <Grid item xs={12}>
            <JobTextField
              required
              label='Profession'
              name='candidateTypeId'
              showError={showError}
              setJobAlertData={setJobAlertData}
              value={values.candidateTypeId}
              options={candidateTypeOptions}
              setState={handleFieldChange}
            />
          </Grid>
          {formSpecialties.length > 0 && (
            <Grid item xs={6}>
              <JobMultiSelectTextField
                label='Specialties'
                name='specialtyIds'
                value={values.specialtyIds || []}
                options={formSpecialties}
                setState={handleFieldChange}
                setJobAlertData={setJobAlertData}
              />
            </Grid>
          )}
          {formSubspecialties.length > 0 && (
            <Grid item xs={6}>
              <JobMultiSelectTextField
                label='Subspecialties'
                name='subspecialtyIds'
                value={values.subspecialtyIds || []}
                options={formSubspecialties}
                setState={handleFieldChange}
                setJobAlertData={setJobAlertData}
              />
            </Grid>
          )}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <JobTextField
                required
                label='Practice Type'
                name='practiceType'
                showError={showError}
                value={values.practiceType}
                options={practiceTypeOptions}
                setState={handleFieldChange}
              />
            </Grid>
            {values.candidateTypeId === 2 && (
              <Grid item xs={6}>
                <JobTextField
                  label='Shift Type'
                  name='shiftType'
                  showError={showError}
                  value={values.shiftType}
                  options={shiftTypeOptions}
                  setState={handleFieldChange}
                  toolTipText={PRACTICE_TYPE_HELPER_TEXT}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <JobTextField
              label='External Link'
              name='link'
              value={values.link}
              setState={handleFieldChange}
            />
          </Grid>
        </Grid>
      </GenericSectionHeader>

      {/* Location Section */}
      <GenericSectionHeader title='Location'>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <JobTextField
              label='City'
              name='city'
              value={values.city}
              setState={handleFieldChange}
              showError={showError}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <JobTextField
              label='State'
              name='state'
              value={values.state}
              options={stateOptions}
              setState={handleFieldChange}
              showError={showError}
              setJobAlertData={setJobAlertData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <JobChipField
              label='Area'
              name='area'
              options={areaOptions}
              icons={AREA_ICONS}
              value={values.area}
              setState={handleFieldChange}
            />
          </Grid>
        </Grid>
      </GenericSectionHeader>

      {/* Benefits Section */}
      <GenericSectionHeader title='Benefits'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <JobChipField
              label='Academic'
              name='academicType'
              options={academicOptions}
              icons={ACADEMIC_ICONS}
              value={values.academicType}
              setState={handleFieldChange}
              tooltipText={ACADEMIC_TYPE_HELPER_TEXT}
            />
          </Grid>
          <Grid item xs={12}>
            <JobCheckboxField
              label='Visa Sponsorship'
              name='visaIds'
              value={values.visaIds} // Ensure this is an array of numbers
              options={visaOptions} // Options should have the structure [{ id: 2, name: "J1" }, { id: 3, name: "O-1" }]
              setState={handleFieldChange}
            />
          </Grid>
          <Grid item xs={6}>
            <JobRadioGroup
              label='Loan Forgiveness'
              name='loanForgiveness'
              setState={handleFieldChange}
              value={values.loanForgiveness}
              options={yesNoOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <JobRadioGroup
              label='PSLF Compatible'
              name='pslfCompatible'
              setState={handleFieldChange}
              value={values.pslfCompatible}
              options={yesNoOptions}
            />
          </Grid>
        </Grid>
      </GenericSectionHeader>

      {/* Compensation Section */}
      <GenericSectionHeader title='Compensation'>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <JobRadioGroup
              label='Salary Type'
              name='salaryType'
              required
              setState={handleFieldChange}
              value={values.salaryType}
              options={salaryTypeOptions}
            />
          </Grid>
          {values.salaryType && (
            <>
              <Grid item xs={12} container spacing={2}>
                {/* Min Salary Field */}
                <Grid item xs={6}>
                  <NumberFormat
                    label='Min Salary'
                    value={values.salaryMin || ''}
                    onValueChange={({ value }) => {
                      if (/^\d*$/.test(value)) {
                        // Ensures the value is numeric
                        handleFieldChange('salaryMin', value ? parseInt(value, 10) : null)
                      }
                    }}
                    thousandSeparator
                    prefix='$'
                    required
                    customInput={TextField}
                    fullWidth
                    size='small'
                    error={
                      values.salaryMin && values.salaryMax && values.salaryMin > values.salaryMax
                    }
                    helperText={
                      values.salaryMin && values.salaryMax && values.salaryMin > values.salaryMax
                        ? 'Min salary must be less than Max salary'
                        : ''
                    }
                  />
                </Grid>
                {/* Max Salary Field */}
                <Grid item xs={6}>
                  <NumberFormat
                    label='Max Salary'
                    value={values.salaryMax || ''}
                    onValueChange={({ value }) => {
                      if (/^\d*$/.test(value)) {
                        // Ensures the value is numeric
                        handleFieldChange('salaryMax', value ? parseInt(value, 10) : null)
                      }
                    }}
                    thousandSeparator
                    prefix='$'
                    customInput={TextField}
                    fullWidth
                    required
                    size='small'
                    error={
                      values.salaryMin && values.salaryMax && values.salaryMin > values.salaryMax
                    }
                    helperText={
                      values.salaryMin && values.salaryMax && values.salaryMin > values.salaryMax
                        ? 'Min salary must be less than Max salary'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </GenericSectionHeader>
      <Grid item xs={12}>
        <TextEditor
          initialValue={values.about || ''}
          onChange={handleEditorChange}
          placeholder='Enter Job Description'
        />
      </Grid>
      <Box display='flex' justifyContent='flex-end' sx={{ marginTop: '10px' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          sx={{ marginLeft: '8px', opacity: !areRequiredFieldsFilled() ? 0.6 : 1 }}
        >
          Save
        </Button>
      </Box>
    </GenericCollapsableSection>
  )
}

export default JobInformationSection
