import axios from 'axios'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'

export const SEND_CONTACT_EMAIL_LOADING = 'contact/SEND_CONTACT_EMAIL_LOADING'
export const SEND_CONTACT_EMAIL_SUCCESS = 'contact/SEND_CONTACT_EMAIL_SUCCESS'
export const SEND_CONTACT_EMAIL_FAILURE = 'contact/SEND_CONTACT_EMAIL_FAILURE'

export const sendContactEmailLoading = () => ({
  type: SEND_CONTACT_EMAIL_LOADING,
})

export const sendContactEmailSuccess = () => ({
  type: SEND_CONTACT_EMAIL_SUCCESS,
})

export const sendContactEmailFailure = () => ({
  type: SEND_CONTACT_EMAIL_FAILURE,
})

export const fetchCreateContactEmail =
  ({ values, onSuccess }) =>
  async dispatch => {
    dispatch(sendContactEmailLoading())
    try {
      await axios({
        method: 'POST',
        url: '/api/emails/contact',
        data: values,
      })
      dispatch(sendContactEmailSuccess())
      dispatch(
        showSnackbar(
          'Message received! We will get back to you within 48 hours.',
          snackbarTypes.SUCCESS,
        ),
      )
      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      dispatch(showSnackbar('Something went wrong, please try again later', snackbarTypes.ERROR))
      dispatch(sendContactEmailFailure())
    }
  }

const initialState = {
  loading: false,
  errors: null,
}

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_CONTACT_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
      }
    case SEND_CONTACT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case SEND_CONTACT_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
