import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, TextField, useTheme } from '@mui/material'
import Loader from 'components/Loader'
import DirectoryTable from './DirectoryTable'
import { fetchAllEmployerDirectory } from 'src/redux/employerDirectory'

const Directory = () => {
  const dispatch = useDispatch()
  const { loading, directoryEntries } = useSelector(state => state.employerDirectory)
  const [healthcareSystems, setHealthcareSystems] = useState(directoryEntries)
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' })
  const [searchText, setSearchText] = useState('')
  const theme = useTheme()

  useEffect(() => {
    dispatch(fetchAllEmployerDirectory())
  }, [dispatch])

  useEffect(() => {
    setHealthcareSystems(directoryEntries)
  }, [directoryEntries])

  useEffect(() => {
    if (searchText) {
      const filtered = directoryEntries.filter(system =>
        system.name.toLowerCase().includes(searchText.toLowerCase()),
      )
      setHealthcareSystems(filtered)
    } else {
      setHealthcareSystems(directoryEntries)
    }
  }, [searchText, directoryEntries])

  return (
    <Grid container alignItems='center' justifyContent='center'>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        sx={{ backgroundColor: theme.palette.secondary.dark }}
      >
        <Grid
          container
          spacing={2}
          alignItems='center'
          justifyContent='center'
          sx={{
            marginBottom: 3,
            padding: '40px 0 20px 0',
            maxWidth: '70em',
          }}
        >
          {/* Search Field */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              InputLabelProps={{
                sx: {
                  fontSize: '0.8rem',
                  color: 'black',
                  '&.Mui-focused': { color: 'white' },
                  '&.MuiInputLabel-shrink': {
                    color: 'white',
                    transform: 'translate(14px, -20px) scale(0.85)',
                  },
                  transform: 'translate(14px, 12px) scale(1)',
                  transition: 'transform 0.2s ease-out, color 0.2s ease-out',
                },
              }}
              InputProps={{
                sx: {
                  height: '36px',
                  padding: '4px 8px',
                  backgroundColor: 'white',
                  borderRadius: 1,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ marginTop: '20px' }}>
        {loading ? (
          <Loader />
        ) : (
          <DirectoryTable
            healthcareSystems={healthcareSystems}
            sortConfig={sortConfig}
            sortData={key => {
              const direction =
                sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
              setSortConfig({ key, direction })
              const sorted = [...healthcareSystems].sort((a, b) => {
                const compareA = a[key]?.toLowerCase() || ''
                const compareB = b[key]?.toLowerCase() || ''
                return direction === 'asc'
                  ? compareA.localeCompare(compareB)
                  : compareB.localeCompare(compareA)
              })
              setHealthcareSystems(sorted)
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Directory
