import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { validateEmail } from 'data/helpers'
import { fetchCreateContactEmail } from 'src/redux/contact'

const DirectoryContactDialog = ({ feedbackOpen, setFeedbackOpen }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    message: '',
  })
  const [errors, setErrors] = useState({})
  const toggleFeedbackDialog = () => {
    setFeedbackOpen(prev => !prev)
    setFormValues({
      email: '',
      firstName: '',
      lastName: '',
      message: '',
    })
    setErrors({})
  }

  const handleInputChange = field => e => {
    setFormValues({ ...formValues, [field]: e.target.value })
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formValues.email || !validateEmail(formValues.email)) {
      newErrors.email = 'Please enter a valid email address.'
    }
    if (!formValues.firstName) {
      newErrors.firstName = 'First name is required.'
    }
    if (!formValues.lastName) {
      newErrors.lastName = 'Last name is required.'
    }
    if (!formValues.message) {
      newErrors.message = 'Please provide details about the incorrect information.'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const closeFeedback = () => {
    setFeedbackOpen(false)
  }
  const handleSubmitFeedback = () => {
    if (!validateForm()) return
    dispatch(fetchCreateContactEmail({ values: formValues, onSuccess: closeFeedback }))
  }

  return (
    <Dialog open={feedbackOpen} onClose={toggleFeedbackDialog}>
      <DialogTitle>Report Incorrect Information</DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1' mb={1}>
          If you are an employee of the hospital and contact information is incorrect, please send
          us a note and we would be happy to update it!
        </Typography>
        <TextField
          fullWidth
          required
          id='email'
          label='Email'
          value={formValues.email}
          onChange={handleInputChange('email')}
          error={!!errors.email}
          helperText={errors.email || ' '}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          required
          id='firstName'
          label='First Name'
          value={formValues.firstName}
          onChange={handleInputChange('firstName')}
          error={!!errors.firstName}
          helperText={errors.firstName || ' '}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          required
          id='lastName'
          label='Last Name'
          value={formValues.lastName}
          onChange={handleInputChange('lastName')}
          error={!!errors.lastName}
          helperText={errors.lastName || ' '}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          required
          id='message'
          label='Details'
          value={formValues.message}
          onChange={handleInputChange('message')}
          multiline
          rows={4}
          error={!!errors.message}
          helperText={errors.message || ' '}
          sx={{ marginBottom: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleFeedbackDialog} color='secondary'>
          Cancel
        </Button>
        <Button onClick={handleSubmitFeedback} color='primary' variant='contained'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DirectoryContactDialog
